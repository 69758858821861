<template>
  <v-app>
    <v-row>
      <v-col
        cols="4"
        class="d-flex flex-column white align-center pa-0 ma-0 justify-space-between"
      >
        <div class="max-width text-start">
          <img
            class="pa-10"
            :src="require(`@/assets/logo.svg`)"
            alt="app logo"
          />
        </div>
        <div class="auth-image-holder max-width text-start">
          <img
            class="white auth-image"
            :src="require(`@/assets/svgs/auth-image.svg`)"
          />
        </div>
      </v-col>
      <v-col class="d-flex lightGray justify-center">
        <router-view />
      </v-col>
    </v-row>
  </v-app>
</template>

<script>
export default {
  name: 'Main',
}
</script>
<style scoped>
.auth-image-holder {
  overflow: hidden;
}
.auth-image {
  width: 100%;
  max-width: 700px;
  min-height: 400px;
}
</style>
